import React, {useState, useEffect} from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts'
import { getTrades } from './Trades'
import typeIDs from './type_ids.json'

export default function Dashboard() {

    const [stats, setStats] = useState({'profit': 0, 'income': 0, 'purchases': 0, 'sales_tax': 0, 'fees': 0})
    const [profitChart, setProfitChart] = useState([])
    const [tradeItems, settradeItems] = useState([])


    const updateData = async() => {
        const corporationTrades = await getTrades({type:'corporation'})

        let income = 0
        let purchases = 0
        let sales_tax = 0
        let fees = 0
        let roi = 0
        
        corporationTrades.forEach(trade => {
            income += trade.total_sell
            purchases += trade.total_buy
            sales_tax += trade.sell_tax
            fees += trade.buy_fee + trade.sell_fee
            roi += trade.roi
        })
        let profit = income - purchases - sales_tax - fees
        setStats({'profit': profit, 'income': income, 'purchases': purchases, 'sales_tax': sales_tax, 'fees': fees, 'avg_roi': roi/corporationTrades.length})

        let lastDate = new Date(corporationTrades[corporationTrades.length-1].date).toISOString().split('T')[0]
        let dayProfit = 0
        let profitData = []
        for (var i = corporationTrades.length-1; i >= 0; i --){
            const trade = corporationTrades[i]
            const date = new Date(trade.date).toISOString().split('T')[0]
            if (date !== lastDate) {
                profitData.push({'date': lastDate, 'profit': Math.round(dayProfit)})
                dayProfit = 0
            }
            dayProfit += trade.profit
            lastDate = date
        }
        profitData.push({'date': lastDate, 'profit': Math.round(dayProfit)})
        setProfitChart(profitData)

        let tradeItemsDict = {}
        for (var j = 0; j < corporationTrades.length; j++){
            const type_id = corporationTrades[j].type_id
            if(!Object.keys(tradeItemsDict).includes(''+type_id)){
                tradeItemsDict[type_id] = {'sold': corporationTrades[j].quantity, 'profit': corporationTrades[j].profit}
            }
            else {
                tradeItemsDict[type_id]['sold'] += corporationTrades[j].quantity
                tradeItemsDict[type_id]['profit'] += corporationTrades[j].profit
            }
        }
        const tradeItems = Object.entries(tradeItemsDict).map(([key, value]) => ({'type_id': key, 'sold': value.sold, 'profit': value.profit}))
        tradeItems.sort((a, b) => b.profit - a.profit)
        settradeItems(tradeItems)
    }

    useEffect(() => {
        updateData()
        const interval=setInterval(() => {
            updateData()
        }, 30000) //30 sec
        return() => clearInterval(interval)
    }, [])

  return (
    <div className='fixed top-12 left-16'>

        <div className='table-container'>
            <div className='flex flex-row mb-10 justify-center'>
                {stats.profit > 0 ? 
                <div className='ml-2'>Profit: <h1 className='text-green-400 font-bold'>{Math.round(stats.profit).toLocaleString()}</h1></div>:
                <div className='ml-2'>Profit: <h1 className='text-red-400 font-bold'>{Math.round(stats.profit).toLocaleString()}</h1></div>
                }
                {stats.avg_roi > 0 ? 
                <div className='ml-2'>Avg. Roi: <h1 className='text-green-400 font-bold'>{(Math.round(stats.avg_roi *10)/10).toLocaleString()}%</h1></div>:
                <div className='ml-2'>Avg. Roi: <h1 className='text-red-400 font-bold'>{(Math.round(stats.avg_roi *10)/10).toLocaleString()}%</h1></div>
                }
                <div className='ml-2'>Income: <h1 className='font-bold'>{Math.round(stats.income).toLocaleString()}</h1></div>
                <div className='ml-2'>Purchases: <h1 className='font-bold'>-{Math.round(stats.purchases).toLocaleString()}</h1></div>
                <div className='ml-2'>Sales Tax: <h1 className='font-bold'>{Math.round(stats.sales_tax).toLocaleString()}</h1></div>
                <div className='ml-2'>Broker Fees: <h1 className='font-bold'>{Math.round(stats.fees).toLocaleString()}</h1></div>
            </div>
            
            <div className='flex flex-row mb-4 justify-center'>
                <BarChart margin={{left:40}} width={730} height={250} data={profitChart}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis tickFormatter={(value) => new Intl.NumberFormat('en').format(value)}/>
                    <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)}/>
                    <Legend />
                    <Bar dataKey="profit" fill="#8884d8" />
                </BarChart>
            </div>
            



            <h1 className='text-center'>Trade Items</h1>
            <table>
                <thead className='border-b'>
                    <tr>
                        <th className='table-head' scope='col'>Item</th>
                        <th className='table-head' scope='col'>Quantity Sold</th>
                        <th className='table-head' scope='col'>Profit</th>
                    </tr>
                </thead>
                <tbody>
                    {tradeItems.map((item, i) => (
                    <tr className= 'table-rows' key={i}>
                        <td className='table-data-first'>{typeIDs[item.type_id].toLocaleString()}</td>
                        <td className='table-data'>{item.sold.toLocaleString()}</td>
                        <td className='table-data'>{Math.round(item.profit).toLocaleString()}</td>
                    </tr>
                    ))} 
                </tbody>
            </table>
        </div>
    </div>

    
  )
}
