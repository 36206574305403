import jwt_decode from 'jwt-decode'
import axios from 'axios';

export default class EveApi {
    constructor(){
        this.url = 'https://esi.evetech.net/latest'

        const token = localStorage.getItem('token')
        if (token) this.character_id = jwt_decode(token).sub.split(':')[2]
        
    }

    tokenValid(token) {
        token = jwt_decode(token)
        const now = Date.now() / 1000;
        const expiry = token.exp -10;
        return now < expiry;
    }

    refreshToken(){
        const clientId = '73cf0edeacb748018f71ea2a9f0a0403'
        const secretKey = 'b76BBlddzAPzxpKxGM2uNysQTHheqJkzOd8IDLI9'
        const auth = btoa(clientId + ':' + secretKey)
        const rt = localStorage.getItem('refresh_token')

        const config = {
            headers: {
                'Authorization': 'Basic ' + auth ,
                'Content-Type': 'application/x-www-form-urlencoded',
                'Host': 'login.eveonline.com'
            }
        }
        const data = `grant_type=refresh_token&refresh_token=${rt}`


        axios.post('https://login.eveonline.com/v2/oauth/token', data, config).then((response) => {
            localStorage.setItem('token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            console.log('Updated Token')
        })
    }

    getConfig(){
        const token = localStorage.getItem('token')
        if(!this.tokenValid(token)) this.refreshToken()
        return {headers: {'Authorization': 'Bearer ' + token}}
    }

    // AUTHORIZED

    // Character
    getCorporationId = async () => {
        return axios.get(this.url+`/characters/${this.character_id}/corporationhistory`, this.getConfig()).then(response => response.data[0].corporation_id)
    }

    // Market
    getOrders = async () => {
        return axios.get(this.url+`/characters/${this.character_id}/orders`, this.getConfig()).then(response => response.data)
    }

    getOrdersHistory = async () => {
        return axios.get(this.url+`/characters/${this.character_id}/orders/history`, this.getConfig()).then(response => response.data)
    }

    getCorporationOrders = async () => {
        const corportaion_id = await this.getCorporationId()
        return axios.get(this.url+`/corporations/${corportaion_id}/orders`, this.getConfig()).then(response => response.data)
    }

    getCorporationOrdersHistory = async () => {
        const corportaion_id = await this.getCorporationId()
        return axios.get(this.url+`/corporations/${corportaion_id}/orders/history`, this.getConfig()).then(response => response.data)
    }

    // Wallet
    getWallet = async () => {
        return axios.get(this.url+`/characters/${this.character_id}/wallet`, this.getConfig()).then(response => response.data)
    }

    getWalletJournal = async ({page}) => {
        return axios.get(this.url+`/characters/${this.character_id}/wallet/journal/?page=${page}`, this.getConfig()).then(response => response)
    }

    getWalletTransactions = async () => {
        return axios.get(this.url+`/characters/${this.character_id}/wallet/transactions`, this.getConfig()).then(response => response.data)
    }

    getCorporationWallet = async ({division}) => {
        const corportaion_id = await this.getCorporationId()
        return axios.get(this.url+`/corporations/${corportaion_id}/wallets`, this.getConfig()).then(response => response.data[division-1].balance)
    }

    getCorporationWalletJournal = async ({division, page}) => {
        const corportaion_id = await this.getCorporationId()
        return axios.get(this.url+`/corporations/${corportaion_id}/wallets/${division}/journal/?page=${page}`, this.getConfig()).then(response => response)
    }

    getCorporationWalletTransactions = async ({division}) => {
        const corportaion_id = await this.getCorporationId()
        return axios.get(this.url+`/corporations/${corportaion_id}/wallets/${division}/transactions`, this.getConfig()).then(response => response.data)
    }

    // UNAUTHORIZED
    getPlayers = async () => {
        return axios.get(this.url+`/status/`).then(response => response.data.players)
    }

    // Universe
    getNames = async (ids) => {
        return axios.post(this.url+`/universe/names`, ids).then(response => response.data)
    }
}