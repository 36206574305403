import React, {useState, useEffect} from 'react'
import EveApi from './eveapi'
import typeIDs from './type_ids.json'

const buy_fee = 1
const sell_fee = 2.4
const eveapi = new EveApi()

export async function getTrades({type='personal'}){
    
    let transactions = []
    if (type === 'personal') transactions = await eveapi.getWalletTransactions()
    else if (type === 'corporation') transactions = await eveapi.getCorporationWalletTransactions({division: 1})

    let journal = []
    if (type === 'personal'){
        let journal_data = await eveapi.getWalletJournal({page:1})
        const pages = journal_data.headers['x-pages']

        for (var i = 1; i <= pages; i++){
            journal_data = await eveapi.getWalletJournal({page:i})
            Array.prototype.push.apply(journal, journal_data.data)
        }
    }
    else if (type === 'corporation'){
        let journal_data = await eveapi.getCorporationWalletJournal({division: 1, page:1})
        const pages = journal_data.headers['x-pages']

        for (var i = 1; i <= pages; i++){
            journal_data = await eveapi.getCorporationWalletJournal({division: 1, page:i})
            Array.prototype.push.apply(journal, journal_data.data)
        }
    }

    let trades = []
    for (var i = 0; i < transactions.length; i++){
        if (!transactions[i].is_buy){
            const sell_type = transactions[i].type_id
            const total_sell_quantity = transactions[i].quantity
            let sell_quantity = transactions[i].quantity
            const sell_price = transactions[i].unit_price
            const sell_date = transactions[i].date
            
            let sell_tax = 0
            for (var l = 0; l < journal.length; l++){
                if (sell_date === journal[l].date && journal[l].ref_type === 'transaction_tax'){
                    sell_tax += journal[l].amount
                }
            }

            let buy_quantity = 0
            let buy_orders = []
            for (var j = transactions.length-1; j >= i; j--){
                if(transactions[j].type_id === sell_type && transactions[j].is_buy){
                    buy_quantity += transactions[j].quantity
                    buy_orders.push(transactions[j])
                }
                if (buy_quantity >= sell_quantity) break
            }
            

            for (var k = 0; k < buy_orders.length; k++){
                const buy_price = buy_orders[k].unit_price
                buy_quantity = 0
                if (sell_quantity-buy_orders[k].quantity > 0)buy_quantity = buy_orders[k].quantity
                else buy_quantity = sell_quantity
                
                const sub_sell_tax = sell_tax*(buy_quantity/total_sell_quantity)
                const cost = buy_price*buy_quantity + buy_price*(buy_fee/100) + sell_price*(sell_fee/100) - sub_sell_tax

                const profit = sell_price*buy_quantity - cost
                if (sell_quantity > 0) {
                    trades.push({
                        'date': sell_date, 'type_id': sell_type, 'buy_price': buy_price, 'sell_price': sell_price, 
                        'quantity': buy_quantity, 'total_buy': buy_price*buy_quantity, 'total_sell': sell_price*buy_quantity,
                        'buy_fee': buy_price*(buy_fee/100), 'sell_fee': sell_price*(sell_fee/100), 'sell_tax': -sub_sell_tax,
                        'profit': profit, 'roi': profit / cost*100
                    })
                }
                sell_quantity -= buy_quantity
            }
        }
    }
    return trades
}

export default function Trades({type='personal'}) {
    const [trades, setTrades] = useState([])


    const updateData = async() => {
        setTrades(await getTrades({type}))
    }

    useEffect(() => {
        updateData()
        const interval=setInterval(() => {
            updateData()
        }, 30000) //30 sec
        return() => clearInterval(interval)
    }, [])

  return (
    <div className='table-container'>
            <h1 className='text-center'>Trades</h1>
            <table>
                <thead className='border-b'>
                    <tr>
                        <th className='table-head' scope='col'>Date</th>
                        <th className='table-head' scope='col'>Item</th>
                        <th className='table-head' scope='col'>Unit Buy/Sell</th>
                        <th className='table-head' scope='col'>Total Buy/Sell</th>
                        <th className='table-head' scope='col'>Fee Buy/Sell</th>
                        <th className='table-head' scope='col'>Sell Tax</th>
                        <th className='table-head' scope='col'>Profit</th>
                        <th className='table-head' scope='col'>Roi</th>
                    </tr>
                </thead>
                <tbody>
                    {trades.map((trade, i) => (
                    <>
                    {trade.profit > 0 ?
                        <tr className= 'table-rows bg-green-100' key={i}>
                        <td className='table-data-first'>{new Date(trade.date).toISOString().replace('T', ' ').substring(0, 16)}</td>
                        <td className='table-data'>{typeIDs[trade.type_id].toLocaleString()}</td>
                        <td className='table-data'>
                            <div className='text-red-400 font-bold'>-{trade.buy_price.toLocaleString()}</div>
                            <div className='text-green-400 font-bold'>{trade.sell_price.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>
                            <div className='text-red-400 font-bold'>{trade.total_buy.toLocaleString()}</div>
                            <div className='text-green-400 font-bold'>{trade.total_sell.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>
                            <div>{trade.buy_fee.toLocaleString()}</div>
                            <div>{trade.sell_fee.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>{trade.sell_tax.toLocaleString()}</td>
                        
                        <td className='table-data text-green-400 font-bold'>{trade.profit.toLocaleString()}</td>
                        
                        <td className='table-data text-green-400 font-bold'>{Math.round(trade.roi).toLocaleString()}%</td>
                        
                    </tr>
                    :
                    <tr className= 'table-rows bg-red-100' key={i}>
                        <td className='table-data-first'>{new Date(trade.date).toISOString().replace('T', ' ').substring(0, 16)}</td>
                        <td className='table-data'>{typeIDs[trade.type_id].toLocaleString()}</td>
                        <td className='table-data'>
                            <div className='text-red-400 font-bold'>-{trade.buy_price.toLocaleString()}</div>
                            <div className='text-green-400 font-bold'>{trade.sell_price.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>
                            <div className='text-red-400 font-bold'>{trade.total_buy.toLocaleString()}</div>
                            <div className='text-green-400 font-bold'>{trade.total_sell.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>
                            <div>{trade.buy_fee.toLocaleString()}</div>
                            <div>{trade.sell_fee.toLocaleString()}</div>
                        </td>
                        <td className='table-data'>{trade.sell_tax.toLocaleString()}</td>
                        
                        <td className='table-data text-red-400 font-bold'>{trade.profit.toLocaleString()}</td>
                        
                        <td className='table-data text-red-400 font-bold'>{Math.round(trade.roi).toLocaleString()}%</td>
                        
                    </tr>}
                    </>
                    ))} 
                </tbody>
            </table>
        </div>
    
  )
}
