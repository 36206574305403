import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import EveApi from './eveapi';
import { BsPersonFill, BsCoin, BsCashCoin, BsPeopleFill } from 'react-icons/bs'

export default function Profile() {
    const token = localStorage.getItem('token')
    let name = ''
    if (token) name = jwt_decode(token).name

    const [wallet, setWallet] = useState(null)
    const [corporationWallet, setCorporationWallet] = useState(null)
    const [players, setPlayers] = useState(null)

    const eveapi = new EveApi()

    const updateData = async () => {
        eveapi.getWallet().then(data => setWallet(data))
        eveapi.getPlayers().then(data => setPlayers(data))
        eveapi.getCorporationWallet({division:1}).then(data => setCorporationWallet(data))
    }

    useEffect(() => {
        updateData()
        const interval=setInterval(() => {
            updateData()
        }, 30000) //30 sec
        return() => clearInterval(interval)
    }, [])
    
    const ProfileItem= ({ icon, href= '/', text }) => (
        <div className='profile-item'>
            <div>
                {icon}
            </div>
            <div className='ml-2'>
                {text}
            </div>
        </div>
      )

    return (
        <div className='profile'>
            <ProfileItem icon={<BsPersonFill size="20"/>} text={name}/>
            <ProfileItem icon={<BsCoin size="20"/>} text={Math.round(wallet).toLocaleString()}/>
            <ProfileItem icon={<BsCashCoin size="20"/>} text={Math.round(corporationWallet).toLocaleString()}/>
            <ProfileItem icon={<BsPeopleFill size="20"/>} text={players}/>
        </div> 
    )
}
