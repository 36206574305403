import React, {useState, useEffect} from 'react'
import EveApi from './eveapi'
import typeIDs from './type_ids.json'
import locationIDs from './location_ids.json'


export default function Orders({type='orders'}) {
    const [buyOrders, setBuyOrders] = useState([])
    const [sellOrders, setSellOrders] = useState([])
    const [totalBuy, setTotalBuy] = useState([])
    const [totalSell, setTotalSell] = useState([])

    const eveapi = new EveApi()

    const sortOrders = async(data) => {
        let buy = []
        let sell = []
        data.map(item => {
            if (item.is_buy_order) buy.push(item)
            else sell.push(item)
        })
        setBuyOrders(buy)
        setSellOrders(sell)

        setTotalBuy(buy.reduce((sum, current) => {return sum+current.volume_remain*current.price}, 0))
        setTotalSell(sell.reduce((sum, current) => {return sum+current.volume_remain*current.price}, 0))
    }

    const updateData = async() => {
        if (type === 'orders') eveapi.getOrders().then(data => sortOrders(data))
        else if (type === 'orders-history') eveapi.getOrdersHistory().then(data => sortOrders(data))
        else if (type === 'corporation-orders') eveapi.getCorporationOrders().then(data => sortOrders(data))
        else if (type === 'corporation-orders-history') eveapi.getCorporationOrdersHistory().then(data => sortOrders(data))
    }

    useEffect(() => {
        updateData()
        const interval=setInterval(() => {
            updateData()
        }, 30000) //30 sec
        return() => clearInterval(interval)
    }, [])


    const OrderTable = ({orders}) => (
        <table>
            <thead className='border-b'>
                <tr>
                    <th className='table-head' scope="col">Item</th>
                    <th className='table-head' scope="col">Total</th>
                    <th className='table-head' scope="col">Volume Remain</th>
                    <th className='table-head' scope="col">Volume Total</th>
                    <th className='table-head' scope="col">Location</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((item, i) => (
                <tr className= 'table-rows' key={i}>
                    <td className='table-data-first'>{typeIDs[item.type_id]}</td>
                    <td className='table-data font-bold'>{Math.round(item.volume_remain*item.price).toLocaleString()}</td>
                    <td className='table-data'>{Math.round(item.volume_remain).toLocaleString()}</td>
                    <td className='table-data'>{Math.round(item.volume_total).toLocaleString()}</td>
                    <td className='table-data'>{item.location_id < 1000000000000 ? locationIDs[item.location_id] : 'Structure'}</td>
                </tr>
                ))} 
            </tbody>
        </table>
    )

    return (
        <div className='table-container'>
            <h1 className='text-center font-bold'>Buying</h1>
            <h1 className='text-center font-bold'>Total ISK: {totalBuy.toLocaleString()}</h1>
       
            <OrderTable orders={buyOrders} />

            <h1 className='text-center font-bold mt-10'>Selling</h1>
            <h1 className='text-center font-bold'>Total ISK: {totalSell.toLocaleString()}</h1>
            <OrderTable orders={sellOrders} />
        </div>
    )
}
