import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Logout() {

    const navigate = useNavigate();
    
    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        navigate('/', {replace: true})
    }

    useEffect(() => {
        logOut()
    }, [])
    return (null)
}
