import React from 'react'
import { BsHouseFill, BsArchiveFill, BsArrowLeftRight, BsXCircleFill, BsPersonFill, BsBuilding, BsFileBarGraphFill, BsGearFill } from 'react-icons/bs'
import { FaBox } from 'react-icons/fa'

export default function Navigation() {

  const NavigationIcon= ({ icon, href= '/', text='tooltip' }) => (
    <a href={href} className='navigation-icon group'>
      {icon}
      <span className='navigation-tooltip group-hover:scale-100'>
        {text}
      </span>
    </a>
  )

  const Divider = ({icon}) => (
    <div className='relative flex items-center justify-center
                    mt-5'>
      
      {icon}
    </div>
  )

  return (
    <div className='fixed top-0 left-0 h-screen w-16 m-0
                    flex flex-col
                    bg-gray-900 text-white shadow-lg z-10'>
      
        <NavigationIcon icon={<BsHouseFill size="28"/>} href='/dashboard' text='Dashboard'/>
        <Divider icon={<BsPersonFill size="28" />}/>
        <NavigationIcon icon={<FaBox size="28"/>} href='/orders' text='Orders'/>
        <NavigationIcon icon={<BsArchiveFill size="28"/>} href='/orders-history' text='Orders History'/>
        <NavigationIcon icon={<BsArrowLeftRight size="28"/>} href='/transactions' text='Transactions'/>
        <NavigationIcon icon={<BsFileBarGraphFill size="28"/>} href='/trades' text='Trades'/>

        <Divider icon={<BsBuilding size="28" />}/>
        <NavigationIcon icon={<FaBox size="28"/>} href='/corporation-orders' text='Corporation Orders'/>
        <NavigationIcon icon={<BsArchiveFill size="28"/>} href='/corporation-orders-history' text='Corporation Orders History'/>
        <NavigationIcon icon={<BsArrowLeftRight size="28"/>} href='/corporation-transactions' text='Corporation Transactions'/>
        <NavigationIcon icon={<BsFileBarGraphFill size="28"/>} href='/corporation-trades' text='Corporation Trades'/>
        <a href='/logout' className='navigation-icon group fixed ml-2 bottom-0'>
          <BsXCircleFill />
          <span className='navigation-tooltip group-hover:scale-100'>
            Logout
          </span>
        </a>
    </div>
  )
}
