import React from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Callback from "./components/login/Callback";
import Login from "./components/login/Login";
import Orders from "./components/eve/Orders";
import Profile from "./components/eve/Profile";
import Protected from "./components/common/Protected";
import Navigation from "./components/common/Navigation";
import Transactions from "./components/eve/Transactions";
import './App.css'
import Logout from "./components/login/Logout";
import Dashboard from "./components/eve/Dashboard";
import Trades from "./components/eve/Trades";


function App() {
  return (
    <div className="flex">
      <BrowserRouter>
        <Routes>
          <Route path="/" element= {<Login />} />
          <Route path="/callback" element= {<Callback />}/>
          <Route path="/dashboard" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Dashboard />
            </Protected>
          }/>
          <Route path="/orders" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Orders type="orders" />
            </Protected>
          }/>
          <Route path="/orders-history" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Orders type="orders-history"/>
            </Protected>
          }/>
          <Route path="/transactions" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Transactions type="personal"/>
            </Protected>
          }/>
          <Route path="/trades" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Trades type="personal"/>
            </Protected>
          }/>
          <Route path="/corporation-orders" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Orders type="corporation-orders"/>
            </Protected>
          }/>
          <Route path="/corporation-orders-history" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Orders type="corporation-orders-history"/>
            </Protected>
          }/>
          <Route path="/corporation-transactions" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Transactions type="corporation"/>
            </Protected>
          }/>
           <Route path="/corporation-trades" element= {
            <Protected>
              <Profile />
              <Navigation/>
              <Trades type='corporation'/>
            </Protected>
          }/>
          <Route path="/logout" element= {
            <Protected>
              <Logout />
            </Protected>
          }/>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
