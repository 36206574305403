import React, {useEffect} from 'react'
import { useSearchParams, useNavigate} from 'react-router-dom';
import axios from 'axios';

export default function Callback() {

    const [searchParams] = useSearchParams();

    const code = searchParams.get("code")

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
    const auth = btoa(CLIENT_ID + ':' + SECRET_KEY)

    const config = {
        headers: {
            'Authorization': 'Basic ' + auth ,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Host': 'login.eveonline.com'
        }
    }
    const data = `grant_type=authorization_code&code=${code}`

    const navigate = useNavigate()

    useEffect(() => {
        axios.post("https://login.eveonline.com/v2/oauth/token", data, config).then((response) => {
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            navigate('/dashboard', {replace: true})
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(null)
}
