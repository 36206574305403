import React, {useState, useEffect} from 'react'
import EveApi from './eveapi'
import typeIDs from './type_ids.json'
import locationIDs from './location_ids.json'

export default function Transactions({type='personal'}) {
    const [transactions, setTransactions] = useState([])

    const eveapi = new EveApi()


    const updateData = async() => {
        if (type === 'personal') eveapi.getWalletTransactions().then(data => setTransactions(data))
        else if (type === 'corporation') eveapi.getCorporationWalletTransactions({division: 1}).then(data => setTransactions(data))
    }

    useEffect(() => {
        updateData()
        const interval=setInterval(() => {
            updateData()
        }, 30000) //30 sec
        return() => clearInterval(interval)
    }, [])

    return (
        <div className='table-container'>
            <h1 className='text-center'>Transactions</h1>
            <table>
                <thead className='border-b'>
                    <tr>
                        <th className='table-head' scope='col'>Date</th>
                        <th className='table-head' scope='col'>Quantity</th>
                        <th className='table-head' scope='col'>Item</th>
                        <th className='table-head' scope='col'>Unit Price</th>
                        <th className='table-head' scope='col'>Total</th>
                        <th className='table-head' scope='col'>Where</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((item, i) => (
                    <tr className= 'table-rows' key={i}>
                        <td className='table-data-first'>{new Date(item.date).toISOString().replace('T', ' ').substring(0, 16)}</td>
                        <td className='table-data'>{item.quantity.toLocaleString()}</td>
                        <td className='table-data'>{typeIDs[item.type_id].toLocaleString()}</td>
                        <td className='table-data'>{item.unit_price.toLocaleString()}</td>
                        {item.is_buy ? 
                            <td className='table-data font-bold text-red-400'>{'-'+Math.round(item.quantity * item.unit_price).toLocaleString()}</td> :
                            <td className='table-data font-bold  text-green-400'>{Math.round(item.quantity * item.unit_price).toLocaleString()}</td> 
                        }
                        <td className='table-data'>{item.location_id < 1000000000000 ? locationIDs[item.location_id] : 'Structure'}</td>
                    </tr>
                    ))} 
                </tbody>
            </table>
        </div>
        
    )
}
