import React from 'react'

export default function Login() {
    const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

    const redirectUri =  encodeURIComponent(CALLBACK_URL)
    const scope = encodeURI('esi-wallet.read_character_wallet.v1 esi-markets.read_character_orders.v1 esi-wallet.read_corporation_wallets.v1 esi-markets.read_corporation_orders.v1')
    for(var state = ''; state.length < 40;) state += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz"[(Math.random() * 60) | 0]
    let path = `https://login.eveonline.com/v2/oauth/authorize/?\
response_type=code&\
redirect_uri=${redirectUri}&\
client_id=${CLIENT_ID}&\
scope=${scope}&\
state=${state}`; 
  

    return (
        <a href= {path}>Login</a>
    )
}
